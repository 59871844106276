<template>
    <ion-page class="ion-page expenses">
        <ion-content>
            <ion-refresher slot="fixed" @ionRefresh="doRefresh">
                <ion-refresher-content></ion-refresher-content>
            </ion-refresher>
            <div class="ei-title">הוצאות</div>
            <ion-list>
                <ion-item>
                    <ion-label>סה"כ הוצאות</ion-label>
                    <ion-badge slot="end" color="secondary">{{formattedExpensesTotal}} ₪</ion-badge>
                </ion-item>
            </ion-list>
            <ion-list>
                <ion-item-sliding v-for="(expanse, expIn) in expenses" :key="expIn">
                    <ion-item-options side="start">
                        <ion-item-option color="danger" @click="remove(expanse)">
                            <ion-icon :icon="icons.trashOutline"></ion-icon>
                        </ion-item-option>
                    </ion-item-options>
                    <ion-item button @click="editExpense(expanse)">
                        <ion-label>
                            <h2>{{expanse.name}}</h2>
                            <h3>סה"כ {{numberFormat(expanse.amount)}} ₪</h3>
                            <p><ion-badge color="success" size="sm">שולם {{numberFormat(expanse.amountPaid)}} ₪</ion-badge> <ion-badge color="danger">יתרה לתשלום {{numberFormat(expanse.amount - expanse.amountPaid)}} ₪ </ion-badge></p>
                            <p>{{expanse.description}}</p>
                        </ion-label>
                        <ion-note slot="end" v-if="expanse.paid">שולם <ion-icon color="success" :icon="icons.checkmarkOutline"></ion-icon></ion-note>
                    </ion-item>
                </ion-item-sliding>
            </ion-list>
            <div class="fab-gap"></div>
            <ion-fab vertical="bottom" horizontal="start" slot="fixed">
                <ion-fab-button @click="editExpense()">
                    <ion-icon :icon="icons.addOutline"></ion-icon>
                </ion-fab-button>
            </ion-fab>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonBadge,
    IonContent,
    IonFab,
    IonFabButton,
    IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonNote,
    IonPage, IonRefresher, IonRefresherContent,
    modalController, toastController
} from "@ionic/vue";
import {addOutline, checkmarkOutline, pencilOutline, trashOutline} from "ionicons/icons";
import {defineComponent} from "vue";
import EditExpense from "@/modals/EditExpense.vue";
import numeral from 'numeral'
import {mapGetters} from "vuex";

export default defineComponent({
    name: "Expenses",
    components: {
        IonPage,
        IonContent,
        IonFab,
        IonFabButton,
        IonIcon,
        IonList,
        IonItem,
        IonLabel,
        IonBadge,
        IonRefresher,
        IonRefresherContent,
        IonNote,
        IonItemSliding,
        IonItemOptions,
        IonItemOption
    },
    computed:{
        formattedExpensesTotal(){
            return this.numberFormat(this.expensesTotal)
        },
        ...mapGetters(['expenses', 'expensesTotal'])
    },
    data(){
        return {
            icons: {
                addOutline,
                checkmarkOutline,
                pencilOutline,
                trashOutline
            }
        }
    },
    methods:{
        numberFormat(number){
            return numeral(number).format('0,0')
        },
        async editExpense(expense = null){
            const modal = await modalController.create({
                component: EditExpense,
                componentProps: {
                    input: expense
                }
            })

            await modal.present()
            const {data, role} = await modal.onDidDismiss()
            if(role === 'save') {
                await this.$store.dispatch('expenseSave', data)
            }
        },
        async doRefresh(e){
            await this.$store.dispatch('expensesLoad')
            e.target.complete()
        },
        async remove(expense){
            if(await this.$store.dispatch('expenseRemove', expense)){
                await (await toastController.create({
                    message: 'הוצאה נמחקה בהצלחה',
                    duration: 1000,
                    color: "primary"
                })).present()
            }else{
                await (await toastController.create({
                    message: 'מחיקת הוצאה נכשלה.',
                    duration: 1000,
                    color: "secondary"
                })).present()
            }
        },
    },
    async mounted() {
        await this.$store.dispatch('expensesLoad')
    }
})
</script>

<style scoped>
ion-badge {
    font-size: .7rem;
    font-weight: normal;
}
.expenses .ei-title {
    text-align: center;
}
</style>
